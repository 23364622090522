import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { UserOrg } from 'types/user';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.root || initialState;

export const selectRoot = createSelector([selectSlice], state => state);

export const selectCurrentUser = createSelector(
  [selectSlice],
  state => state.currentUser,
);

export const selectIsAssessor = createSelector([selectSlice], state =>
  state.currentUser?.user_permissions?.some(
    permission => permission.org === 'assessor',
  ),
);

export const selectVendorPermissions = createSelector([selectSlice], state =>
  state.currentUser?.user_permissions?.filter(
    permission => permission.org === 'vendor',
  ),
);

export const selectAssessorPermissions = createSelector([selectSlice], state =>
  state.currentUser?.user_permissions?.filter(
    permission => permission.org === 'assessor',
  ),
);

export const selectCurrentPermissions = (org: UserOrg, id?: string) =>
  createSelector([selectSlice], state =>
    state.currentUser?.user_permissions?.find(
      permission =>
        permission.questionnaire_id === id && permission.org === org,
    ),
  );

export const selectLastPermissionSet = createSelector([selectSlice], state =>
  state.currentUser?.user_permissions &&
  state.currentUser?.user_permissions.length > 0
    ? state.currentUser?.user_permissions[
        state.currentUser?.user_permissions?.length - 1
      ]
    : undefined,
);
