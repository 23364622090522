export const abstractDataNames = [
  'questionnaireSummary',

  'requiredDocuments',
  'processList',
  'documentList',
  'categoryList',
  'collaboratorList',
  'batchList',
  'recordList',
  'modelList',
  'targetEntityList',
  'evidenceReportList',
  'brandInfo',
  'reminderList',
  'exportGroups',

  'processListFilter',
  'historyList',
] as const;
